import serviceConfig from "../../serviceConfig_dev.json";
import {
  browserName,
  isBrowser,
  isAndroid,
  isWinPhone,
  isIos,
} from "react-device-detect";
import { setCookie, getCookie, toCamelCase, getParameterByName } from "helpers";
import { defaultLanguage } from "../redux/app/constants";

const { projectConfig } = serviceConfig;

export const env = process.env.NODE_ENV; // eslint-disable-line

let config = serviceConfig;

if (env === "development") {
  config = { ...serviceConfig, adminApp: "http://localhost:3001" };
}

export const environment = config.env;

export const isProduction = env === "production";

export const {
  baseURL,
  adminApp,
  adminBE,
  videoLabelingApp,
  baseURLAdmin,
  amazonS3Config,
  engineURL,
  socketServerURL,
  GATrackingId,
  toolName,
  exportSegmentationImageProject,
  // sentryDSN,
} = config;

export const sentryDSN =
  "https://f488a47d283c482c84d42872fcd666b0@sentry.blueeye.ai/4";
export const token = getCookie("Bearer");
export const userInfo = getCookie("userInfo");

export const commonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `JWT ${token}`,
    locale: localStorage.getItem("currentLanguage") || defaultLanguage,
  },
  timeout: 10000,
  credentials: "same-origin",
};

export const commonHeaderAdmin = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    locale: localStorage.getItem("currentLanguage") || defaultLanguage,
  },
  timeout: 10000,
  credentials: "same-origin",
};

export function payloadToCamelCase(payload) {
  const successStatus = payload.success || payload.status;
  if (payload && successStatus && payload.data) {
    if (Array.isArray(payload.data)) {
      const data = payload.data.map((item) => toCamelCase(item));
      return data;
    }
    const pack = toCamelCase(payload.data);
    return pack;
  }
  return null;
}

export const firebaseConfig = {
  apiKey: "AIzaSyC7oudwev3Bi3hLxxGczzB8DLNxJ7aC1oM",
  authDomain: "seta-salf.firebaseapp.com",
  databaseURL: "https://seta-salf.firebaseio.com",
  projectId: "seta-salf",
  storageBucket: "seta-salf.appspot.com",
  messagingSenderId: "840010130280",
  appId: "1:840010130280:web:be227f73f40e3e7201acdd",
  measurementId: "G-VXHJZ7HFK1",
};

fetch("https://api.ipify.org/?format=json")
  .then((response) => response.json())
  .then((data) => {
    setCookie("localIP", data.ip);
  });

const platform = () => {
  if (isBrowser) {
    return "Browser";
  }
  if (isAndroid) {
    return "Android";
  }
  if (isWinPhone) {
    return "Win Phone";
  }
  if (isIos) {
    return "Ios";
  }
  return "Others";
};

export const userLogCommonHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `JWT ${token}`,
    device: `${browserName}`,
    env,
    // type: "labeling-image",
    platform: platform(),
    ip: getCookie("localIP"),
    projectId: getParameterByName("projectId"),
    projectDetail: JSON.stringify({
      name: getParameterByName("projectName", window.location.href, false),
      data_type: getParameterByName("projectType"),
    }),
  },
  timeout: 10000,
  credentials: "include",
  locale: localStorage.getItem("currentLanguage") || defaultLanguage,
};

// export const GATrackingId = "UA-186932005-1";

export let socketOptions = {
  transports: ["websocket"],
  query: {
    connectType: "realtime-collaboration",
  },
};

export { projectConfig };
